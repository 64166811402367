<!--
 * @Descripttion: 个人中心页面组件
 * @Author: Banbri
 * @Date: 2021-07-03 09:31:23
 * @LastEditors: Banbri
 * @LastEditTime: 2021-06-08 17:05:32
--> 

<template>
  <div class="center" id="center" name="center">
    <div class="center-layout">
      <el-row :gutter="10">
        <div>
          <CenterMenu></CenterMenu>
        </div>
        <el-col :span="20">
          <div class="center-content">
            <!--个人信息头部-->
            <div class="center-user">
              <div class="user-avator">
                <img :src="$target +this.$store.getters.getUser.avatar" />
              </div>
              <div class="user-info">
                <p
                  style="font-size:25px;font-weight:100;color:#464547"
                >{{this.$store.getters.getUser.nick_name}}</p>
                <p>
                  <router-link to="/user/details" style="font-size:13px;color:#ff6700;">修改个人信息 ></router-link>
                </p>
              </div>
            </div>
            <!--end--个人信息头部-->
            <div class="user-details">
              <div class>
                <img src="https://s01.mifile.cn/i/user/portal-icon-1.png" />
              </div>
              <div class="operate1">
                <p class="oper-title">待支付订单：{{notPayTotal}}</p>
                <p>
                  <router-link :to="{ path: '/order', query: {status:0} }" class="oper-href">查看待支付订单 ></router-link>
                </p>
              </div>
              <div class="operate">
                <img src="https://s01.mifile.cn/i/user/portal-icon-2.png" />
              </div>
              <div class="operate1">
                <p class="oper-title">待收货订单：{{payTotal}}</p>
                <p>
                  <router-link :to="{ path: '/order', query: {status:2} }" class="oper-href">查看待收货订单 ></router-link>
                </p>
              </div>
            </div>
            <div class="user-details1">
              <div class>
                <img src="https://s01.mifile.cn/i/user/portal-icon-3.png" />
              </div>
              <div class="operate1">
                <p class="oper-title">待评价商品：{{notCommentTotal}}</p>
                <p>
                  <router-link :to="{ path: '/order', query: {status:3} }" class="oper-href">查看待评价订单 ></router-link>
                </p>
              </div>
              <div class="operate">
                <img src="https://s01.mifile.cn/i/user/portal-icon-4.png" />
              </div>
              <div class="operate1">
                <p class="oper-title">收藏的商品：{{favoriteTotal}}</p>
                <p>
                  <router-link :to="{ path: '/favorite'}" class="oper-href">查看收藏的商品 ></router-link>
                </p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import CenterMenu from '../components/CenterMenu'
export default {
  name: 'Center',
  data() {
    return {
      favoriteTotal: 0,
      notPayTotal: 0,
      payTotal: 0,
      notCommentTotal:0,
    }
  },
  components: {
    CenterMenu
  },
  created(){
    this.getTotal();
  },
  methods:{
    getTotal(){
      this.$axios
        .get("/api/common/getAmount", {
          params:{user_id: this.$store.getters.getUser.user_id}
        })
        .then(res => {
          if (res.data.code === "001") {
            this.favoriteTotal = res.data.favorite_total;
            this.notPayTotal = res.data.not_pay_total;
            this.payTotal = res.data.pay_total;
            this.notCommentTotal = res.data.not_comment_total;
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }
  }
}
</script>
<style scoped>
.center-layout {
  max-width: 1225px;
  margin: 0 auto;
}
.center-content {
  background-color: #ffffff;
  margin-bottom: 30px;
}
.center-user {
  height: 280px;
  width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0;
  border-bottom: 1px solid #c2c4ca;
}
.user-avator img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid #ddd;
}
.user-info {
  width: 260px;
  line-height: 30px;
  margin-left: 15px;
  padding: 0;
}
.user-message {
  line-height: 30px;
  padding: 0;
}
.user-message ul li {
  color: #77787b;
  font-size: 15px;
}
.user-data {
  line-height: 30px;
  padding: 0;
}
.user-data ul li {
  color: #77787b;
  font-size: 15px;
}
.user-data ul li a {
  color: #77787b;
}
.user-data ul li a:hover {
  color: #ff6700;
}
.user-details {
  height: 150px;
  width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-top: 50px;
}
.user-details1 {
  height: 150px;
  width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-bottom: 50px;
}
.operate {
  margin-left: 200px;
}
.operate1 {
  line-height: 30px;
  margin-left: 20px;
}
.operate1 .oper-title {
  font-size: 20px;
  color: #7b7f86;
}
.operate1 .oper-href {
  font-size: 15px;
  color: #7b7f86;
}
.operate1 .oper-href:hover {
  color: #ff6700;
}
</style>