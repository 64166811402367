import { render, staticRenderFns } from "./Center.vue?vue&type=template&id=34af9852&scoped=true&"
import script from "./Center.vue?vue&type=script&lang=js&"
export * from "./Center.vue?vue&type=script&lang=js&"
import style0 from "./Center.vue?vue&type=style&index=0&id=34af9852&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34af9852",
  null
  
)

export default component.exports